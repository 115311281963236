import { Injectable } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';

@Injectable({
  providedIn: 'root',
})
export class GthRemoteConfigService {
  constructor(private remoteConfig: AngularFireRemoteConfig) { }

  async communityBetaEnabled(): Promise<boolean> {
    const betaEnabled = await this.remoteConfig.getString('enableCommunityBeta');
    if (betaEnabled === 'ON') {
      return true;
    }

    return false;
  }

  async getSubscriptionsEnabled(): Promise<boolean> {
    return this.getValueByKey('enableSubscriptions')
      .catch(() => false);
  }

  async getTournamentsEnabled(): Promise<boolean> {
    return this.getValueByKey('enableTournaments')
      .catch(() => false);
  }

  async getConversationDialogEnabled(): Promise<boolean> {
    return this.getValueByKey('enableConversationUserSearch')
      .catch(() => false);
  }

  async getBadgesEnabled(): Promise<boolean> {
    return this.getValueByKey('enableBadges')
      .catch(() => false);
  }

  /**
  * Get remote config value by key
  * @param {String} key: Name of the js doc
  */
  private async getValueByKey(key: string): Promise<any> {
    return this.remoteConfig.getBoolean(key).then((value) => {
      return value;
    }).catch((err) => {
      return err;
    });
  }
}
